<template>
	<div v-if="text">
		<Order>
			<div v-html="text"></div>
		</Order>
	</div>
</template>

<script>
	import Order from "../Order";
  import gAds from "../../../google";

	export default {
		name: "OrderBankPayment",
		metaInfo: {
			title: "Děkujeme za objednávku",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order},
		computed: {
			text() {
				let text = this.$api.texts[this.textId];
				if (!text || !this.$storage.order)
					return null;

				return text.raw
					.replace('_CENA_', this.$price(this.$storage.order.total))
					.replace('_VS_', this.$storage.order.code);
			},
      textId() {
        const simpleTextId = "order-done-bank";

        if (this.$route.query.predplatne ?? null) {
          const id = `${simpleTextId}-subscription`;
          if (this.$api.texts[id]) {
            return id;
          }
        }

        return simpleTextId;
      }
		},
		mounted() {
			if (!this.$storage.order) {
        this.$router.push({
          name: "Home"
        });
        return;
      }

			gAds.conversion(this.$storage.order.total);
		}
	}
</script>